/* eslint-disable react-hooks/rules-of-hooks */
'use client';
import React, { useMemo } from 'react';
import { ROUTES } from '@/constants/routes/default';
import Logo from '@public/cn/logo.svg';

import Link from 'next/link';
import { cn } from '@/helpers/common';
import { useSession, signOut } from 'next-auth/react';
import HeaderDropdown from '../Dropdowns/HeaderDropdown';

const HeaderCN = ({ skipSession = false }: { skipSession?: boolean }) => {
  let session = null;
  if (!skipSession) {
    const { data: value } = useSession();
    session = value;
  }
  const options = useMemo(() => {
    let result = [];

    if (session) {
      result = [
        {
          type: 'link',
          title: 'My Account',
          href: ROUTES.profile,
          withDivider: true,
        },
        { type: 'button', title: 'Sign out', onClick: () => signOut(), withDivider: true },
      ];
    } else {
      result = [
        {
          type: 'link',
          title: 'Login',
          href: ROUTES.signin,
          withDivider: false,
        },
        {
          type: 'link',
          title: 'Sign Up',
          href: ROUTES.signup,
          withDivider: false,
        },
      ];
    }

    return result;
  }, [!!session]);

  return (
    <header className="flex md:px-10 max-md:px-4 min-h-[80px] border-b border-black3">
      <div className="max-w-[1200px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4">
        <Link href={ROUTES.home}>
          <Logo className="w-[140px] h-4 flex-shrink-0" />
        </Link>
        {!session ? (
          <div className={'md:flex max-md:hidden gap-8 justify-center items-center'}>
            <a
              className="no-underline default-transition font-bold text-jelly-bean hover:text-black"
              href={ROUTES.signin}
            >
              Login
            </a>
            <a
              className={cn(
                'no-underline default-transition px-5 py-[7px] font-bold border rounded-lg',
                'text-jelly-bean hover:text-white border-jelly-bean hover:border-black hover:bg-black',
              )}
              href={ROUTES.signup}
            >
              Sign Up
            </a>
          </div>
        ) : (
          <div className={'md:flex max-md:hidden gap-8 justify-center items-center'}>
            <a
              className="no-underline default-transition font-bold text-jelly-bean hover:text-black"
              href={ROUTES.profile}
            >
              My Account
            </a>
            <button
              className={cn(
                'no-underline default-transition px-5 py-[7px] font-bold border rounded-lg',
                'text-jelly-bean hover:text-white border-jelly-bean hover:border-black hover:bg-black',
              )}
              onClick={() => signOut()}
            >
              Sign out
            </button>
          </div>
        )}
        <div className="md:hidden max-md:block">
          <HeaderDropdown
            classNames={{
              listCn: 'max-w-[200px] right-0 bg-white py-3 rounded-lg custom-shadow-cn-2',
              elementCn: 'text-sm h-9 px-4 flex items-center hover:bg-jelly-bean-light2',
              rootCn: '',
              iconCn:
                'h-9 w-9 rounded-lg border-2 border-jelly-bean [&>svg>path]:!fill-jelly-bean [&>svg>path]:hover:!fill-black hover:border-black',
              activeIconCn: '[&>svg>path]:!fill-black border-black',
            }}
            isAuthorized={!!session}
            introviewUrl={''}
            isSighInPath={false}
            listHeader={
              session && session?.user?.email ? (
                <div
                  className={cn(
                    'text-xs overflow-hidden whitespace-nowrap text-ellipsis',
                    'px-3.5 py-2 text-dove-gray',
                  )}
                >
                  {session?.user?.email}
                </div>
              ) : null
            }
            customOptions={options}
          />
        </div>
      </div>
    </header>
  );
};

export default HeaderCN;
